/* Basic style for the container */
.typewriter-container {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
  }
  
  /* Keyframe for the typewriting effect */
  @keyframes typing {
    from {
      width: 0;  /* Start with the text hidden */
    }
    to {
      width: 100%;  /* End with the full text revealed */
    }
  }
  
  /* Blinking cursor */
  @keyframes blink-caret {
    50% {
      border-color: transparent;
    }
  }
  
  /* Apply typing animation to each line */
  .typewriter-container h1,
  .typewriter-container p,
  .typewriter-container span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    border-right: 3px solid black; /* Cursor effect */
    animation: typing 3s steps(40) 1s forwards, blink-caret 0.75s step-end infinite;
  }
  
  /* Add animation delay for each line (this controls the sequential typing) */
  .typewriter-container h1 {
    animation-delay: 0s;
  }
  
  .typewriter-container p:nth-child(2) {
    animation-delay: 3s; /* Delay after the first line */
  }
  
  .typewriter-container p:nth-child(3) {
    animation-delay: 6s; /* Delay after the second line */
  }
  
  .typewriter-container span {
    animation-delay: 9s; /* Delay after the paragraphs */
  }
  