.react-chatbot-kit-chat-container {
  position: relative !important;
  width: 100% !important;
  height: calc(var(--height-adjustment, -100px) + 100vh);
}

.react-chatbot-kit-chat-inner-container {
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 5px;
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: white;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
  border-bottom: solid thin #cdcdcd;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 10px 17.5px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow (optional) */
  height: calc(-175px + 100vh);
}

/* Style for the scrollbar itself */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  width: 5px; /* Vertical scrollbar width */
}

/* Style for the scrollbar thumb (draggable part) */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
  background: #8b8b8b; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

/* Style for the scrollbar thumb when hovered */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb:hover {
  background: #8b8b8b; /* Darker color on hover */
}

/* Style for the scrollbar track (background area) */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
  background: #ffffff; /* Color of the track */
  border-radius: 4px; /* Rounded corners */
}
.react-chatbot-kit-chat-input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: none;
  /* border-bottom-left-radius: 10px; */
  background-color: #f3f3f3;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
  background-color: #f3f3f3
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #53B146;
  width: 20px;
  margin: 0 auto;
  padding-top: 5px;
  margin-right: 5px;
}

.react-chatbot-kit-chat-bot-message-container {
  display: inline-flex !important;
  margin: 15px 0;
  justify-content: flex-start !important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2898ec;
  position: absolute;
  left: -7px;
  top: 13px;
  display: none;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  color: black;
  font-weight: medium;
  position: relative;
  width: 100% !important;
  margin-left: auto;
  text-align: left;
}

.react-chatbot-kit-user-chat-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  color: #585858;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
  display: none;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #f3f3f3;
  width: auto;
  border: none;
  color: #fff;
  border-radius: 50%;
  /* padding: 5px; */
  margin: 3px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Add these styles to your existing chatbotCustomStyle.css */

/* Container for the tabbed interface */
.tabbed-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 10px 1px grey;
}

/* Tab navigation bar */
.chat-tabs {
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

/* Tab content area */
.tab-content {
  flex: 1;
  overflow-y: auto;
  height: calc(100% - 50px); /* Adjust based on tab height */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Chat tab styling */
.chat-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Incidents tab styling */
.incidents-tab {
  padding: 16px;
}

.tab-heading {
  margin-top: 0;
  margin-bottom: 16px;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.incidents-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.incident-item {
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.incident-item h4 {
  margin-top: 0;
  margin-bottom: 8px;
  color: #444;
  font-size: 16px;
  font-weight: 500;
}

.incident-item p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

/* Knowledge tab styling */
.knowledge-tab {
  padding: 16px;
}

.knowledge-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.knowledge-item {
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.knowledge-item h4 {
  margin-top: 0;
  margin-bottom: 8px;
  color: #444;
  font-size: 16px;
  font-weight: 500;
}

.knowledge-item p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.react-chatbot-kit-chat-input:focus {
  border-color: #2898ec !important;
}