.options-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .option-button {
    margin: 5px;
    border-radius: 25px;
    padding: 8px;
    border-color: #53B146;
    background: transparent;
    text-align: center;
  }
  